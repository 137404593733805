body {
  margin: 0;
  background-color: #141414;
  color: #2b2b2b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
/* .ant-layout {
  background: linear-gradient(
    to top left,
    #ff85a2,
    #ffe4e9,
    #ffffff
  ) !important;
} */
